<template>
  <div id="app">
    <Layout />
  </div>
</template>

<script>
import Layout from '@/components/Layout/Layout'

export default {
  name: 'app',
  components: {
    Layout
  }
}
</script>

<style>
  #app {
    font-family: 'interstate', sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  .fevo-coco-panel-open .a5-widget-container {
    opacity: 0;
    pointer-events: none;
  }
</style>
