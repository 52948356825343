<template>
  <div id="secondaryFooter" v-if="contact || inquiry || appointment || chat">
    <b-container>
      <div class="footer__container">
        <a class="footer__item" v-if="contact && repInfo.first == 'Admin'">
          <svg width="30" height="22" viewBox="0 0 30 22" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M27.18 21.3c.654 0 1.22-.243 1.7-.73s.72-1.06.72-1.722h-4.78c.652 0 1.209-.244 1.67-.73.46-.487.69-1.061.69-1.723V3.052c0-.661-.23-1.235-.69-1.722C26.029.843 25.472.6 24.82.6H5.58c-.652 0-1.209.243-1.67.73a2.422 2.422 0 00-.69 1.722v13.343c0 .662.23 1.236.69 1.723.461.486 1.018.73 1.67.73H.8c0 .661.24 1.235.72 1.722.48.487 1.046.73 1.7.73h23.96zm-2.36-4.905H5.58V3.052h19.24v13.343zm-9.62 3.65a1.13 1.13 0 01-.835-.35 1.162 1.162 0 01-.346-.847c0-.331.115-.614.346-.847.23-.234.509-.35.835-.35.326 0 .605.116.835.35.23.233.346.516.346.847 0 .33-.115.613-.346.846a1.13 1.13 0 01-.835.35z" fill="#BBB" fill-rule="nonzero"/></svg>
          <div class="middle first">
            <h3 class="hover">Contact<br />Us</h3>
            <div class="first__bottom">
              <a class="hover" href="tel:718-760-6236"
                >Telephone: (718) 760-6236</a
              >
              <a class="hover" href="mailto:USOpenHospitality@usta.com"
                >Email: USOpenHospitality@usta.com</a
              >
            </div>
          </div>
          <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'svg-hover'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path fill="#262626" fill-rule="nonzero" d="M18.9 12.6l-6.3-6.3-1.142 1.105 4.42 4.421H6.3v1.548h9.579l-4.384 4.42L12.6 18.9z"/><circle stroke="#373737" stroke-width="1.8" cx="12.6" cy="12.6" r="12.6"/></g></svg>
        </a>
        <a class="footer__item" v-else-if="contact">
          <svg width="30" height="22" viewBox="0 0 30 22" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M27.18 21.3c.654 0 1.22-.243 1.7-.73s.72-1.06.72-1.722h-4.78c.652 0 1.209-.244 1.67-.73.46-.487.69-1.061.69-1.723V3.052c0-.661-.23-1.235-.69-1.722C26.029.843 25.472.6 24.82.6H5.58c-.652 0-1.209.243-1.67.73a2.422 2.422 0 00-.69 1.722v13.343c0 .662.23 1.236.69 1.723.461.486 1.018.73 1.67.73H.8c0 .661.24 1.235.72 1.722.48.487 1.046.73 1.7.73h23.96zm-2.36-4.905H5.58V3.052h19.24v13.343zm-9.62 3.65a1.13 1.13 0 01-.835-.35 1.162 1.162 0 01-.346-.847c0-.331.115-.614.346-.847.23-.234.509-.35.835-.35.326 0 .605.116.835.35.23.233.346.516.346.847 0 .33-.115.613-.346.846a1.13 1.13 0 01-.835.35z" fill="#BBB" fill-rule="nonzero"/></svg>
          <div class="middle first">
            <h3 class="hover">Contact<br />Us</h3>
            <div class="first__bottom">
              <a class="hover" :href="'tel:' + repInfo.phone"
                >Telephone: {{ repInfo.phone }}</a
              >
              <a class="hover" :href="'mailto:' + repInfo.email"
                >Email: {{ repInfo.email }}</a
              >
            </div>
          </div>
          <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'svg-hover'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path fill="#262626" fill-rule="nonzero" d="M18.9 12.6l-6.3-6.3-1.142 1.105 4.42 4.421H6.3v1.548h9.579l-4.384 4.42L12.6 18.9z"/><circle stroke="#373737" stroke-width="1.8" cx="12.6" cy="12.6" r="12.6"/></g></svg>
        </a>
        <div class="vertical-line" v-if="contact && inquiry"></div>
        <!-- <router-link
          class="footer__item"
          v-if="inquiry"
          to="/inquiry"
          v-on:click="trackingTag"
        >
          <svg width="24" height="19" viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.094 18.9c.637 0 1.181-.236 1.631-.707.45-.472.675-1.028.675-1.67V2.377c0-.642-.225-1.198-.675-1.67A2.173 2.173 0 0021.094 0H2.306C1.67 0 1.125.236.675.707A2.338 2.338 0 000 2.377v14.146c0 .642.225 1.198.675 1.67.45.471.994.707 1.631.707h18.788zM11.7 8.29L2.306 2.377h18.788L11.7 8.29zm9.394 8.233H2.306V4.753L11.7 10.61l9.394-5.857v11.77z" fill-rule="nonzero"/></svg>
          <div class="middle">
            <h3 class="hover">Inquiry<br />Form</h3>
            <p class="hover">
              Submit an inquiry online and we will contact you with more
              information
            </p>
          </div>
          <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'svg-hover'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path fill="#262626" fill-rule="nonzero" d="M18.9 12.6l-6.3-6.3-1.142 1.105 4.42 4.421H6.3v1.548h9.579l-4.384 4.42L12.6 18.9z"/><circle stroke="#373737" stroke-width="1.8" cx="12.6" cy="12.6" r="12.6"/></g></svg>
        </router-link> -->
        <a
          class="footer__item"
          v-if="inquiry"
          v-on:click="trackingTag"
          @click="
            openLightbox(),
              buyFloodlight('DC-10973038/butto0/kpi_u009+standard')
          "
        >
          <svg width="24" height="19" viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.094 18.9c.637 0 1.181-.236 1.631-.707.45-.472.675-1.028.675-1.67V2.377c0-.642-.225-1.198-.675-1.67A2.173 2.173 0 0021.094 0H2.306C1.67 0 1.125.236.675.707A2.338 2.338 0 000 2.377v14.146c0 .642.225 1.198.675 1.67.45.471.994.707 1.631.707h18.788zM11.7 8.29L2.306 2.377h18.788L11.7 8.29zm9.394 8.233H2.306V4.753L11.7 10.61l9.394-5.857v11.77z" fill-rule="nonzero"/></svg>
          <div class="middle">
            <h3 class="hover">Inquiry<br />Form</h3>
            <p class="hover">
              Submit an inquiry online and we will contact you with more
              information
            </p>
          </div>
          <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'svg-hover'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path fill="#262626" fill-rule="nonzero" d="M18.9 12.6l-6.3-6.3-1.142 1.105 4.42 4.421H6.3v1.548h9.579l-4.384 4.42L12.6 18.9z"/><circle stroke="#373737" stroke-width="1.8" cx="12.6" cy="12.6" r="12.6"/></g></svg>
        </a>
        <div
          class="vertical-line"
          v-if="(contact || inquiry) && (appointment || chat)"
        ></div>
        <a
          class="footer__item"
          v-if="appointment && appointmentLink"
          :href="appointmentLink"
          target="_blank"
          @click="buyFloodlight('DC-10973038/butto0/kpi_u00-+standard')"
        >
          <svg width="26" height="27" viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill-rule="nonzero" fill="none"><path d="M19.568 26.92c.313 0 .58-.11.8-.331.222-.221.332-.488.332-.8V21.73c0-.313-.11-.58-.331-.8a1.09 1.09 0 00-.8-.331c-1.51 0-2.871-.212-4.085-.635a1.469 1.469 0 00-.635-.028.92.92 0 00-.552.304l-2.54 2.539a17.905 17.905 0 01-4.388-3.23 17.905 17.905 0 01-3.229-4.388l2.54-2.539a.92.92 0 00.303-.552c.037-.22.027-.432-.028-.635-.423-1.214-.635-2.576-.635-4.084 0-.313-.11-.58-.33-.8a1.09 1.09 0 00-.801-.332H1.132c-.313 0-.58.11-.8.331a1.09 1.09 0 00-.332.8c0 2.65.515 5.19 1.546 7.618a19.49 19.49 0 004.195 6.21 19.49 19.49 0 006.21 4.195 19.31 19.31 0 007.617 1.546z" fill="#BBB"/><path stroke="#BBB" stroke-width="1.656" d="M14.4 12.52l3.3-3.245h6.6V1.72h-9.9z"/></g></svg>
          <div class="middle">
            <h3 class="hover">Private<br />Appointment</h3>
            <p class="hover">
              Schedule an appointment to consult with a hospitality
              representative
            </p>
          </div>
          <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'svg-hover'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path fill="#262626" fill-rule="nonzero" d="M18.9 12.6l-6.3-6.3-1.142 1.105 4.42 4.421H6.3v1.548h9.579l-4.384 4.42L12.6 18.9z"/><circle stroke="#373737" stroke-width="1.8" cx="12.6" cy="12.6" r="12.6"/></g></svg>
        </a>
        <div
          class="footer__item"
          v-else-if="appointment"
          @click="
            (privateAppointmentShow = !privateAppointmentShow),
              buyFloodlight('DC-10973038/butto0/kpi_u00-+standard')
          "
        >
          <svg width="26" height="27" viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill-rule="nonzero" fill="none"><path d="M19.568 26.92c.313 0 .58-.11.8-.331.222-.221.332-.488.332-.8V21.73c0-.313-.11-.58-.331-.8a1.09 1.09 0 00-.8-.331c-1.51 0-2.871-.212-4.085-.635a1.469 1.469 0 00-.635-.028.92.92 0 00-.552.304l-2.54 2.539a17.905 17.905 0 01-4.388-3.23 17.905 17.905 0 01-3.229-4.388l2.54-2.539a.92.92 0 00.303-.552c.037-.22.027-.432-.028-.635-.423-1.214-.635-2.576-.635-4.084 0-.313-.11-.58-.33-.8a1.09 1.09 0 00-.801-.332H1.132c-.313 0-.58.11-.8.331a1.09 1.09 0 00-.332.8c0 2.65.515 5.19 1.546 7.618a19.49 19.49 0 004.195 6.21 19.49 19.49 0 006.21 4.195 19.31 19.31 0 007.617 1.546z" fill="#BBB"/><path stroke="#BBB" stroke-width="1.656" d="M14.4 12.52l3.3-3.245h6.6V1.72h-9.9z"/></g></svg>
          <div class="middle">
            <h3 class="hover">Private<br />Appointment</h3>
            <p class="hover">
              Schedule an appointment to consult with a hospitality
              representative
            </p>
          </div>
          <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'svg-hover'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path fill="#262626" fill-rule="nonzero" d="M18.9 12.6l-6.3-6.3-1.142 1.105 4.42 4.421H6.3v1.548h9.579l-4.384 4.42L12.6 18.9z"/><circle stroke="#373737" stroke-width="1.8" cx="12.6" cy="12.6" r="12.6"/></g></svg>
        </div>
        <div class="vertical-line" v-if="appointment && chat"></div>
        <a
          class="footer__item"
          v-if="chat"
          href="https://chat.satis.fi/?pageID=21752"
          target="_blank"
        >
          <svg width="24" height="23" viewBox="0 0 24 23" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill-rule="nonzero" fill="none"><path d="M4.238 23l2.93-2.488c.222-.11.461-.166.72-.166h4.975c.7 0 1.327-.156 1.88-.47.553-.313.885-.746.995-1.3l-1.437-.994a1.744 1.744 0 00-.94-.277H6.892c-.958 0-1.787-.313-2.488-.94-.7-.626-1.05-1.4-1.05-2.322V8.46h-.11c-.701 0-1.3.24-1.798.719a2.36 2.36 0 00-.746 1.77v7.131c0 .664.258 1.207.774 1.631.516.424 1.143.636 1.88.636h.884V23z" fill="#BBB"/><path d="M8.22.828c-.69 0-1.245.205-1.647.66-.425.482-.622 1.096-.622 1.83v9.288c0 .686.257 1.268.764 1.748.524.496 1.155.741 1.891.741h5.584c.771 0 1.229.198 1.48.42l2.779 2.224v-2.644h1.768c.736 0 1.367-.245 1.891-.741.508-.48.764-1.062.764-1.748V3.317c0-.686-.256-1.268-.764-1.748C21.453.95 20.515.828 8.22.828z" stroke="#BBB" stroke-width="1.656"/></g></svg>
          <div class="middle">
            <h3 class="hover">Live<br />Chat</h3>
            <p class="hover">
              Need answers now? Live chat with one of our hospitality
              representatives
            </p>
          </div>
          <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'svg-hover'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path fill="#262626" fill-rule="nonzero" d="M18.9 12.6l-6.3-6.3-1.142 1.105 4.42 4.421H6.3v1.548h9.579l-4.384 4.42L12.6 18.9z"/><circle stroke="#373737" stroke-width="1.8" cx="12.6" cy="12.6" r="12.6"/></g></svg>
        </a>
      </div>
      <b-modal id="modal-5" v-model="modalShow">
        <div class="modal__close" @click="modalShow = !modalShow">
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
        </div>
        <iframe
          src="https://usopen.formstack.com/forms/hospitality_luxury_suites"
          title="Hospitality Luxury Suites"
        ></iframe>
      </b-modal>

      <b-modal id="modal-5" v-model="privateAppointmentShow" hide-footer>
        <div
          class="modal__close"
          @click="privateAppointmentShow = !privateAppointmentShow"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
        </div>
        <template v-if="$route.fullPath == '/theoverlook'">
          <iframe
            class="privateFrame"
            src="https://app.acuityscheduling.com/schedule.php?owner=19209178&amp;calendarID=3713234&field:8429482=Website_Overlook"
            title="Schedule Appointment"
          ></iframe>
        </template>
        <template v-else-if="$route.fullPath == '/1968room'">
          <iframe
            class="privateFrame"
            src="https://app.acuityscheduling.com/schedule.php?owner=19209178&amp;calendarID=3713234&field:8429482=Website_1968"
            title="Schedule Appointment"
          ></iframe>
        </template>
        <template v-else-if="$route.fullPath == '/suites'">
          <iframe
            class="privateFrame"
            src="https://app.acuityscheduling.com/schedule.php?owner=19209178&amp;calendarID=3713234&field:8429482=Website_Luxury_Suite"
            title="Schedule Appointment"
          ></iframe>
        </template>
        <template v-else-if="$route.fullPath == '/americanexpressclub'">
          <iframe
            class="privateFrame"
            src="https://app.acuityscheduling.com/schedule.php?owner=19209178&amp;calendarID=3713234&field:8429482=Website_Center_Court"
            title="Schedule Appointment"
          ></iframe>
        </template>
        <template v-else-if="$route.fullPath == '/privatehospitality'">
          <iframe
            class="privateFrame"
            src="https://app.acuityscheduling.com/schedule.php?owner=19209178&amp;calendarID=3713234&field:8429482=Website_Private_Hospitality"
            title="Schedule Appointment"
          ></iframe>
        </template>
        <template v-else>
          <iframe
            class="privateFrame"
            src="https://app.acuityscheduling.com/schedule.php?owner=19209178&amp;calendarID=3713234&field:8429482=Website_Private_Appointment"
            title="Schedule Appointment"
          ></iframe>
        </template>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SecondaryFooter",
  data() {
    return {
      modalShow: false,
      privateAppointmentShow: false,
      contact: null,
      inquiry: null,
      appointment: null,
      chat: null,
      appointmentLink: null,
      repInfo: null,
    };
  },
  methods: {
    trackingTag: function () {
      var axel = Math.random() + "";
      var a = axel * 10000000000000;
      var axel2 = Math.random() + "";
      var b = axel2 * 10000000000000;

      let floodlightTag = document.createElement("script");
      let floodlightTag2 = document.createElement("script");
      floodlightTag.setAttribute(
        "src",
        "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur002;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
          a +
          "?"
      );
      floodlightTag2.setAttribute(
        "src",
        "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur003;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=" +
          b +
          "?"
      );
      document.body.appendChild(floodlightTag);
      document.body.appendChild(floodlightTag2);
    },
    openLightbox() {
      window.fsLightbox2933987.show();
    },
    buyFloodlight(id) {
      window.gtagClick(id);
    },
  },
  mounted() {
    this.$store.watch((state) => {
      let sets = state.epitch.ePitch.pages;
      this.repInfo = state.epitch.ePitch.rep;

      // window.console.log(this.$route);

      if (typeof sets !== "undefined") {
        sets.forEach((page) => {
          if (page.id === "1001") {
            let set = JSON.parse(page.copy);

            this.contact = set["globalLinkOutput"]["cta1"];
            this.inquiry = set["globalLinkOutput"]["cta2"];
            this.appointment = set["globalLinkOutput"]["cta3"];
            this.chat = set["globalLinkOutput"]["cta4"];
            this.appointmentLink = set["calendarLink"];
          }
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
#secondaryFooter {
  .footer {
    &__container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      @media (max-width: $lg) {
        flex-direction: column;
        padding: 40px 0 60px 0;
      }
      .vertical-line {
        background: #d0d0d0;
        height: 137px;
        width: 1px;
        @media (max-width: $lg) {
          height: 1px;
          width: 100%;
        }
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      height: 230px;
      max-width: 275px;
      margin: 80px 0 110px 0;
      text-decoration: none;
      cursor: pointer;
      svg {
        fill: #bbb;
      }
      .hover {
        transition: color 0.3s ease;
      }
      &:hover .hover {
        color: #418fde;
      }
      .svg-hover {
        g {
          path {
            transition: fill 0.3s ease;
          }
          circle {
            transition: stroke 0.3s ease;
          }
        }
      }
      &:hover .svg-hover {
        g {
          path {
            fill: #418fde;
          }
          circle {
            stroke: #418fde;
          }
        }
      }

      @media (max-width: $lg) {
        height: auto;
        padding: 50px 0;
        margin: 0;
      }
      .middle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 50%;
        width: 75%;
        min-width: 207px;
        font-family: "interstate", sans-serif;
        letter-spacing: 0;
        text-align: center;
        line-height: 21px;
        @media (max-width: $xl) {
          min-width: auto;
        }
        @media (max-width: $lg) {
          height: auto;
          padding: 30px 0 30px 0;
          width: 65%;
        }

        h3 {
          font-size: 16px;
          font-weight: 700;
          font-style: normal;
          color: $text-black;
          @media (max-width: $lg) {
            padding-bottom: 20px;
          }
        }
        p {
          font-size: 12px;
          font-weight: 500;
          color: $text-grey;
          font-style: italic;
        }
        a {
          font-size: 12px;
          font-weight: 500;
          color: $text-grey;
          text-decoration: none;
          font-style: italic;
        }
      }
      .first {
        @media (max-width: $lg) {
          width: 100%;
        }
        &__bottom {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>