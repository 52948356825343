import axios from "axios";
import router from "@/router";
const state = {
  url: "https://usopen.vipfanportal.com/",
  ePitch: {},
  summary: {},
  introVideo: {},
  primaryMenu: [],
};

const getters = {
  getEpitch: (state) => state.ePitch,
  getSummary: (state) => state.summary,
};

const actions = {
  async fetchEpitch({ commit }) {
    const url = location.pathname.split("/");
    let purl = url[1];
    // let purl = url[2];
    let mode = "live";

    if (purl && purl.includes("preview.")) {
      purl = purl.replace("preview.", "");
      mode = "preview";
    }

    if (
      typeof purl === "undefined" ||
      purl === "" ||
      purl.split(".").length <= 1
    ) {
      purl = "admin.0000";
    }
    axios;
    axios
      .get(
        "https://usopen.vipfanportal.com/epitchApi/SiteEndpoint.php?purl=" +
          purl.replace("/", "") +
          "&d=" +
          new Date().getTime()
      )
      .then(function(response) {
        if (response.data.status == "ok") {
          response.data.site.mode = mode;
          response.data.site.purl = purl;

          let pages = response.data.site.pages;

          commit("setPurl", purl);
          commit("setEpitch", response.data.site);
          commit("setSectionsRoute", pages);
          commit("setPrimaryMenu", pages);
        } else {
          console.log("err", response.data.status);
          //this is an error in my endpoint, or it could also be incorrectly sent information
        }
      });
  },
};

const mutations = {
  setEpitch: (state, payload) => {
    state.ePitch = payload;
  },
  setPurl: (state, payload) => {
    state.purl = payload;
  },
  setSummary: (state, payload) => {
    state.summary = payload;
  },
  setIntroVideo: (state, payload) => {
    state.introVideo = payload;
  },
  setSectionsRoute: (state, payload) => {
    let routes = [];

    payload.forEach((page) => {
      if (page && page.vuePath) {
        routes.push({
          path: `${page.vuePath}`,
          name: page.title,
          component: () =>
            import(
              `@/components/sections/${page.vueComponent}/${page.vueComponent}`
            ),
        });
      }
    });
    router.options.routes.push(...routes);
    router.addRoutes(routes);
  },
  setPrimaryMenu: (state, payload) => {
    // window.console.log("payload", payload);

    payload.forEach((page) => {
      if (page.primaryMenu) {
        if (page.id === "9" && typeof page.attachment === "undefined") {
          return;
        }
        state.primaryMenu.push(page);
      }
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
