<template>
  <div
    id="secondaryNav"
    data-aos="fade-down"
    data-aos-duration="500"
    v-if="inquiry || appointment || chat"
  >
    <b-container>
      <div
        class="nav__container"
        data-aos="fade-left"
        data-aos-delay="500"
        data-aos-duration="500"
      >
        <a
          class="item"
          v-if="inquiry"
          v-on:click="trackingTag"
          @click="
            openLightbox(),
              buyFloodlight('DC-10973038/butto0/kpi_u009+standard')
          "
        >
          <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-3.6%" y="-43.8%" width="107.3%" height="187.5%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><g filter="url(#a)" transform="translate(-737 6)" fill="#418FDE" fill-rule="nonzero"><path d="M769.916 25c.3 0 .555-.112.767-.337a1.12 1.12 0 00.317-.795v-6.736c0-.306-.106-.57-.317-.795a1.015 1.015 0 00-.767-.337h-8.832c-.3 0-.555.112-.767.337a1.12 1.12 0 00-.317.795v6.736c0 .306.106.57.317.795.212.225.467.337.767.337h8.832zm-4.416-5.052l-4.416-2.816h8.832l-4.416 2.816zm4.416 3.92h-8.832v-5.605l4.416 2.79 4.416-2.79v5.605z"/></g></svg>
          <p>Inquiry Form</p>
        </a>
        <a
          class="item"
          v-if="appointment && appointmentLink"
          :href="appointmentLink"
          target="_blank"
          @click="buyFloodlight('DC-10973038/butto0/kpi_u00-+standard')"
        >
          <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-3.6%" y="-43.8%" width="107.3%" height="187.5%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><g filter="url(#a)" transform="translate(-867 7)" fill-rule="nonzero" fill="none"><path d="M899.35 25.04c.149 0 .276-.053.382-.158a.521.521 0 00.158-.383v-1.938a.521.521 0 00-.158-.383.521.521 0 00-.383-.158c-.72 0-1.371-.1-1.951-.303a.702.702 0 00-.304-.013.44.44 0 00-.263.145l-1.213 1.213a8.555 8.555 0 01-2.097-1.543 8.555 8.555 0 01-1.543-2.097l1.213-1.213a.44.44 0 00.145-.263.702.702 0 00-.013-.304c-.202-.58-.303-1.23-.303-1.951a.521.521 0 00-.158-.383.521.521 0 00-.383-.158h-1.938a.521.521 0 00-.383.158.521.521 0 00-.158.383 9.312 9.312 0 002.743 6.607 9.312 9.312 0 006.606 2.742z" fill="#418FDE"/><path stroke="#418FDE" stroke-width=".791" d="M896.88 18.16l1.577-1.55h3.153V13h-4.73z"/></g></svg>
          <p>Private Appointment</p>
        </a>
        <div
          class="item"
          v-else-if="appointment"
          @click="
            (privateAppointmentModalShow = !privateAppointmentModalShow),
              buyFloodlight('DC-10973038/butto0/kpi_u00-+standard')
          "
        >
          <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-3.6%" y="-43.8%" width="107.3%" height="187.5%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><g filter="url(#a)" transform="translate(-867 7)" fill-rule="nonzero" fill="none"><path d="M899.35 25.04c.149 0 .276-.053.382-.158a.521.521 0 00.158-.383v-1.938a.521.521 0 00-.158-.383.521.521 0 00-.383-.158c-.72 0-1.371-.1-1.951-.303a.702.702 0 00-.304-.013.44.44 0 00-.263.145l-1.213 1.213a8.555 8.555 0 01-2.097-1.543 8.555 8.555 0 01-1.543-2.097l1.213-1.213a.44.44 0 00.145-.263.702.702 0 00-.013-.304c-.202-.58-.303-1.23-.303-1.951a.521.521 0 00-.158-.383.521.521 0 00-.383-.158h-1.938a.521.521 0 00-.383.158.521.521 0 00-.158.383 9.312 9.312 0 002.743 6.607 9.312 9.312 0 006.606 2.742z" fill="#418FDE"/><path stroke="#418FDE" stroke-width=".791" d="M896.88 18.16l1.577-1.55h3.153V13h-4.73z"/></g></svg>
          <p>Private Appointment</p>
        </div>
        <!-- </a> -->
        <a
          class="item"
          v-if="chat"
          href="https://chat.satis.fi/?pageID=21752"
          target="_blank"
        >
          <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-3.6%" y="-43.8%" width="107.3%" height="187.5%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><g filter="url(#a)" transform="translate(-1016 7)" fill-rule="nonzero" fill="none"><path d="M1040.83 25.891l1.514-1.286a.82.82 0 01.372-.086h2.573c.362 0 .686-.081.971-.243.286-.162.458-.386.515-.672l-.743-.514a.902.902 0 00-.486-.143h-3.345c-.495 0-.924-.162-1.286-.486a1.547 1.547 0 01-.543-1.2v-2.888h-.057c-.362 0-.672.124-.93.372a1.22 1.22 0 00-.385.915v3.687c0 .343.133.624.4.843.267.22.59.329.972.329h.457v1.372z" fill="#418FDE"/><path d="M1042.887 14.428c-.355 0-.643.106-.85.341-.22.25-.322.567-.322.946v4.802c0 .355.132.656.395.904.272.258.601.385.984.383l3.523.12 1.742 1.393.41-1.54c.52.086.954-.03 1.299-.356.262-.248.395-.549.395-.904v-4.802c0-.355-.133-.656-.395-.904-.339-.32-.824-.383-7.18-.383z" stroke="#418FDE" stroke-width=".856"/></g></svg>
          <p>Live Chat</p>
        </a>
      </div>
      <b-modal id="modal-5" v-model="inquireModalShow" hide-footer>
        <div class="modal__close" @click="inquireModalShow = !inquireModalShow">
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
        </div>
        <iframe
          class="inquireFrame"
          src="https://usopen.formstack.com/forms/hospitality_luxury_suites"
          title="Hospitality Luxury Suites"
        ></iframe>
      </b-modal>
      <b-modal id="modal-5" v-model="privateAppointmentModalShow" hide-footer>
        <div
          class="modal__close"
          @click="privateAppointmentModalShow = !privateAppointmentModalShow"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
        </div>
        <iframe
          class="privateFrame"
          src="https://app.acuityscheduling.com/schedule.php?owner=19209178&amp;calendarID=3713234&field:8429482=Website_Private_Appointment"
          title="Schedule Appointment"
        ></iframe>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SecondaryNav",
  watch: {
    $route(to, from) {
      document.querySelectorAll("#secondaryNav .aos-init").forEach((el) => {
        el.classList.remove("aos-animate");
      });
    },
  },
  data() {
    return {
      inquireModalShow: false,
      privateAppointmentModalShow: false,
      contact: null,
      inquiry: null,
      appointment: null,
      chat: null,
      appointmentLink: null,
    };
  },
  methods: {
    trackingTag: function () {
      var axel = Math.random() + "";
      var a = axel * 10000000000000;
      var axel2 = Math.random() + "";
      var b = axel2 * 10000000000000;

      let floodlightTag = document.createElement("script");
      let floodlightTag2 = document.createElement("script");
      floodlightTag.setAttribute(
        "src",
        "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur002;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
          a +
          "?"
      );
      floodlightTag2.setAttribute(
        "src",
        "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur003;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=" +
          b +
          "?"
      );
      document.body.appendChild(floodlightTag);
      document.body.appendChild(floodlightTag2);
    },
    openLightbox() {
      window.fsLightbox2933987.show();
    },
    buyFloodlight(id) {
      window.gtagClick(id);
    },
  },
  mounted() {
    this.$store.watch((state) => {
      let sets = state.epitch.ePitch.pages;
      if (typeof sets !== "undefined") {
        sets.forEach((page) => {
          if (page.id === "1001") {
            let set = JSON.parse(page.copy);

            this.contact = set["globalLinkOutput"]["cta1"];
            this.inquiry = set["globalLinkOutput"]["cta2"];
            this.appointment = set["globalLinkOutput"]["cta3"];
            this.chat = set["globalLinkOutput"]["cta4"];
            this.appointmentLink = set["calendarLink"];
          }
        });

        // window.console.log("inquire", this.inquiry);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
#secondaryNav {
  background: linear-gradient(-55deg, #262626 50%, #333333 50%);
  width: 100vw;
  z-index: 100;
  .nav__container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 40px;

    .item {
      display: flex;
      flex-direction: row;
      // justify-content: space-around;
      align-items: center;
      text-decoration: none;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        color: #418fde;
      }
      svg {
        margin-top: 5px;
        margin-right: -10px;
        margin-left: 5px;
        outline: none;
      }
      p {
        font-family: "interstate", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 10px;
        letter-spacing: 0;
      }
      transition: color 1.5s ease;
    }
    // .visible {
    //   visibility: hidden;
    // }
    .link {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      color: #ffffff;
      &:hover {
        color: #418fde;
      }
      svg {
        // margin-top: 5px;
        margin: 46px 12px;
        outline: none;
      }
      p {
        font-family: "interstate", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 10px;
        letter-spacing: 0;
      }
      transition: color 1.5s ease;
    }
  }
}
</style>

<style lang="scss">
#modal-5 {
  background-image: linear-gradient(
    269deg,
    rgba(0, 40, 140, 0.7) 1%,
    rgba(0, 10, 35, 0.7) 100%
  );
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    // width: 60vw;
    @media (max-width: $xl) {
      left: 45%;
    }
    @media (max-width: $lg) {
      left: 47%;
    }
  }
  .modal-content {
    border-radius: 5px;
    background: #ffffff;
    border: 0;
    width: 55vw;
    @media (max-width: $xl) {
      width: 55vw;
    }
    @media (max-width: $lg) {
      width: 55vw;
    }
  }
  .modal-body {
    padding: 0;
    width: 55vw;
    // height: 90vh;
    // height: auto;
    @media (min-width: $lg) {
      width: 55vw;
    }
  }
  iframe {
    width: 55vw;
    height: 60vh;
    border: 0px;
    // @media (max-width: $lg) {
    //   width: 55vw;
    // }
  }
  // .inquireFrame {
  //   width: 55vw;
  // }
  // .privateFrame {
  //   width: 52vw;
  // }
  .modal__close {
    position: absolute;
    top: -10px;
    right: -80px;
    width: 58px;
    height: 58px;
    border: 1px solid #ffffff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9;
    @media (max-width: $md) {
      height: 48px;
      width: 48px;
      right: -65px;
    }
    @media (max-width: $sm) {
      height: 32px;
      width: 32px;
      right: -48px;
    }
    @media (max-width: 450px) {
      height: 26px;
      width: 26px;
      right: -38px;
    }
    svg {
      outline: none;
      @media (max-width: $md) {
        width: 12px;
        height: 12px;
      }
      @media (max-width: $sm) {
        width: 10px;
        height: 10px;
      }
      @media (max-width: 450px) {
        width: 8px;
        height: 8px;
      }
    }
  }
  .modal-header {
    height: 7px;
    padding: 0;
    button {
      display: none;
    }
  }
  // .modal-footer {
  //   height: 10px;
  //   padding: 0;
  // }
  .btn {
    display: none;
  }
}
</style>