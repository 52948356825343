<template>
  <div class="topNav">
    <b-container>
      <div class="topNav__menu">
        <a href="https://www.usopen.org" target="_blank">
          <img src="@/assets/icons/nav/logo_main.svg" />
        </a>
      </div>
    </b-container>
    <!-- <b-modal ref="covid" id="covid" hide-header hide-footer>
      <div class="modal__close" @click="hideModal">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
      </div>
      <h2>2020 US Open Update</h2>
      <p>
        Keeping the health and well-being of all fans, ticket holders, players, volunteers and staff
        as the top priority, the USTA has come to the difficult decision that the 2020 US Open will be
        held this August without fans in attendance.  We look forward to welcoming our hospitality
        guests back to next year's US Open.  
        <br><br>
        Explore the premium hospitality packages available for the 2021 US Open today.
      </p>
      <button @click="hideModal">Explore Now</button>
    </b-modal> -->
    <div
      class="mobileMenuBtn"
      @click="openMenu = !openMenu"
      :class="{ active: openMenu }"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
    <transition name="expand">
      <div class="collapse-1" v-if="openMenu">
        <nav id="topNavMobile" is-nav>
          <!-- <div class="covid-mobile" @click="showModal">
            <h4>2020 US Open Update</h4>
            <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path fill="#fff" fill-rule="nonzero" d="M18.9 12.6l-6.3-6.3-1.142 1.105 4.42 4.421H6.3v1.548h9.579l-4.384 4.42L12.6 18.9z"/><circle stroke="#fff" stroke-width="1.8" cx="12.6" cy="12.6" r="12.6"/></g></svg>
          </div> -->

          <b-nav class="topNavMobile__top" v-if="primaryMenu">
            <li v-for="(page, index) in primaryMenu" :key="'nav' + index">
              <router-link
                v-if="page.title == 'Home'"
                active-class="active"
                exact
                :to="page.vuePath"
              >
                <p>{{ page.title }}</p>
              </router-link>
              <router-link
                v-else-if="page.title !== ('Travel Packages' || 'Schedule')"
                active-class="active"
                :to="page.vuePath"
              >
                <p
                  v-if="page.title == 'American Express Center Court Club'"
                  @click="openMenu = !openMenu"
                >
                  AMERICAN EXPRESS&reg; CENTER COURT CLUB
                </p>
                <p
                  v-else-if="page.title == 'Private Hospitality'"
                  @click="openMenu = !openMenu"
                >
                  PRIVATE HOSPITALITY SPACE
                </p>
                <p v-else @click="openMenu = !openMenu">{{ page.title }}</p>
              </router-link>
              <a
                v-if="page.title == 'Schedule'"
                v-bind:href="`https://usopen.vipfanportal.com/upload/${page.attachment}`"
                target="_blank"
              >
                <p>{{ page.title }}</p>
              </a>
              <!-- <a
                v-else-if="page.title == 'Travel Packages'"
                :href="travelLink.redirectLink"
                target="_blank"
              > -->
              <a
                v-else-if="page.title == 'Travel Packages'"
                href="https://onlocationexp.com/tennis/us-open-tennis-tickets?utm_source=hospitality.usopen.org&utm_medium=referral&utm_campaign=us_open-travel_packages_main_menu"
                target="_blank"
              >
                <p @click="openMenu = !openMenu">TRAVEL PACKAGES</p>
              </a>
            </li>
            <!-- <li v-if="schedule">
              <a v-bind:href="schedule" target="_blank">
                <p @click="openMenu = !openMenu">Schedule</p>
              </a>
            </li> -->
          </b-nav>
        </nav>
        <div class="topNavMobile__bottom">
          <div class="social">
            <a href="https://www.facebook.com/usopentennis/" target="_blank">
              <svg width="8" height="16" viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.035 15.44V7.887h2.203l.314-2.517H5.035V3.875c0-.367.072-.63.216-.786.145-.158.466-.237.964-.237h1.337V.335H5.35c-1.285 0-2.203.282-2.754.846-.55.564-.826 1.396-.826 2.498V5.37H0v2.517h1.77v7.553h3.265z" fill="#418fde" fill-rule="nonzero"/></svg>
            </a>
            <a href="https://www.instagram.com/usopen/?hl=en" target="_blank">
              <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.504 16.304c1.304-.063 2.46-.36 3.414-1.315.954-.954 1.251-2.11 1.315-3.414.063-1.124.053-2.237.053-3.36 0-1.125.01-2.238-.053-3.362-.064-1.304-.36-2.46-1.315-3.414-.954-.954-2.11-1.251-3.414-1.315C10.38.061 9.267.071 8.143.071S5.906.061 4.782.124c-1.304.064-2.46.36-3.414 1.315C.414 2.393.117 3.55.053 4.853c-.048.843-.054 1.68-.054 2.52L0 8.214c0 1.124-.01 2.237.053 3.361.064 1.304.36 2.46 1.315 3.414.954.955 2.11 1.252 3.414 1.315.843.048 1.68.054 2.52.054h.84c1.125 0 2.238.01 3.362-.054zm-3.361-1.41c-1.188 0-3.732.095-4.803-.329a2.603 2.603 0 01-.933-.615 2.603 2.603 0 01-.615-.933c-.424-1.07-.329-3.615-.329-4.803 0-1.187-.095-3.732.329-4.803.148-.37.329-.647.615-.933.286-.286.562-.466.933-.615 1.07-.424 3.615-.328 4.803-.328 1.187 0 3.732-.096 4.803.328.371.149.647.329.933.615.286.286.466.562.615.933.424 1.071.329 3.616.329 4.803 0 1.188.095 3.732-.33 4.803a2.603 2.603 0 01-.614.933 2.603 2.603 0 01-.933.615c-1.071.424-3.616.329-4.803.329zM12.49 4.843c.54 0 .975-.435.975-.976a.973.973 0 00-.975-.975.973.973 0 00-.975.975c0 .54.434.976.975.976zm-4.347 7.549a4.172 4.172 0 004.177-4.178 4.172 4.172 0 00-4.177-4.177 4.172 4.172 0 00-4.178 4.177 4.172 4.172 0 004.178 4.178zm0-1.463a2.72 2.72 0 01-2.714-2.715A2.72 2.72 0 018.143 5.5a2.72 2.72 0 012.714 2.714 2.72 2.72 0 01-2.714 2.715z" fill="#418fde" fill-rule="nonzero"/></svg>
            </a>
            <a
              href="https://twitter.com/usopen?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
            >
              <svg width="18" height="14" viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.725 14c6.298 0 9.744-5.217 9.744-9.744 0-.148 0-.297-.01-.445a7.362 7.362 0 001.717-1.77 6.866 6.866 0 01-1.972.53A3.415 3.415 0 0016.71.682c-.658.392-1.4.679-2.174.827A3.416 3.416 0 0012.034.429 3.423 3.423 0 008.61 3.853c0 .265.032.53.085.785a9.734 9.734 0 01-7.061-3.584 3.4 3.4 0 00-.467 1.728c0 1.188.605 2.238 1.527 2.852A3.45 3.45 0 011.145 5.2v.042a3.425 3.425 0 002.746 3.361 3.62 3.62 0 01-.901.117c-.223 0-.435-.021-.647-.053a3.43 3.43 0 003.202 2.375 6.859 6.859 0 01-4.251 1.463 7.08 7.08 0 01-.827-.042A9.677 9.677 0 005.725 14z" fill="#418fde" fill-rule="nonzero"/></svg>
            </a>
          </div>
          <div class="buttons" v-if="inquiry || appointment || chat">
            <!-- <a
              class="buttons__item"
              @click="navModalShow = !navModalShow"
              v-on:click="trackingTag"
              v-if="inquiry"  
            > -->
            <!-- <router-link
              class="buttons__item"
              to="/inquiry"
              v-on:click="trackingTag"
              v-if="inquiry"  
            >
              <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-3.6%" y="-43.8%" width="107.3%" height="187.5%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><g filter="url(#a)" transform="translate(-737 6)" fill="#418FDE" fill-rule="nonzero"><path d="M769.916 25c.3 0 .555-.112.767-.337a1.12 1.12 0 00.317-.795v-6.736c0-.306-.106-.57-.317-.795a1.015 1.015 0 00-.767-.337h-8.832c-.3 0-.555.112-.767.337a1.12 1.12 0 00-.317.795v6.736c0 .306.106.57.317.795.212.225.467.337.767.337h8.832zm-4.416-5.052l-4.416-2.816h8.832l-4.416 2.816zm4.416 3.92h-8.832v-5.605l4.416 2.79 4.416-2.79v5.605z"/></g></svg>
              <p>Inquiry<br>Form</p>
            </router-link> -->
            <a
              class="buttons__item"
              v-on:click="trackingTag"
              v-if="inquiry"
              @click="
                openLightbox(),
                  buyFloodlight('DC-10973038/butto0/kpi_u009+standard')
              "
            >
              <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-3.6%" y="-43.8%" width="107.3%" height="187.5%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><g filter="url(#a)" transform="translate(-737 6)" fill="#418FDE" fill-rule="nonzero"><path d="M769.916 25c.3 0 .555-.112.767-.337a1.12 1.12 0 00.317-.795v-6.736c0-.306-.106-.57-.317-.795a1.015 1.015 0 00-.767-.337h-8.832c-.3 0-.555.112-.767.337a1.12 1.12 0 00-.317.795v6.736c0 .306.106.57.317.795.212.225.467.337.767.337h8.832zm-4.416-5.052l-4.416-2.816h8.832l-4.416 2.816zm4.416 3.92h-8.832v-5.605l4.416 2.79 4.416-2.79v5.605z"/></g></svg>
              <p>Inquiry<br />Form</p>
            </a>
            <!-- </a> -->
            <a
              class="buttons__item"
              v-if="appointment && appointmentLink"
              :href="appointmentLink"
              target="_blank"
              @click="buyFloodlight('DC-10973038/butto0/kpi_u00-+standard')"
            >
              <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-3.6%" y="-43.8%" width="107.3%" height="187.5%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><g filter="url(#a)" transform="translate(-867 7)" fill-rule="nonzero" fill="none"><path d="M899.35 25.04c.149 0 .276-.053.382-.158a.521.521 0 00.158-.383v-1.938a.521.521 0 00-.158-.383.521.521 0 00-.383-.158c-.72 0-1.371-.1-1.951-.303a.702.702 0 00-.304-.013.44.44 0 00-.263.145l-1.213 1.213a8.555 8.555 0 01-2.097-1.543 8.555 8.555 0 01-1.543-2.097l1.213-1.213a.44.44 0 00.145-.263.702.702 0 00-.013-.304c-.202-.58-.303-1.23-.303-1.951a.521.521 0 00-.158-.383.521.521 0 00-.383-.158h-1.938a.521.521 0 00-.383.158.521.521 0 00-.158.383 9.312 9.312 0 002.743 6.607 9.312 9.312 0 006.606 2.742z" fill="#418FDE"/><path stroke="#418FDE" stroke-width=".791" d="M896.88 18.16l1.577-1.55h3.153V13h-4.73z"/></g></svg>
              <p>Private<br />Appointment</p>
            </a>
            <a
              class="buttons__item"
              @click="
                (privateAppointmentShow = !privateAppointmentShow),
                  buyFloodlight('DC-10973038/butto0/kpi_u00-+standard')
              "
              v-else-if="appointment"
            >
              <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-3.6%" y="-43.8%" width="107.3%" height="187.5%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><g filter="url(#a)" transform="translate(-867 7)" fill-rule="nonzero" fill="none"><path d="M899.35 25.04c.149 0 .276-.053.382-.158a.521.521 0 00.158-.383v-1.938a.521.521 0 00-.158-.383.521.521 0 00-.383-.158c-.72 0-1.371-.1-1.951-.303a.702.702 0 00-.304-.013.44.44 0 00-.263.145l-1.213 1.213a8.555 8.555 0 01-2.097-1.543 8.555 8.555 0 01-1.543-2.097l1.213-1.213a.44.44 0 00.145-.263.702.702 0 00-.013-.304c-.202-.58-.303-1.23-.303-1.951a.521.521 0 00-.158-.383.521.521 0 00-.383-.158h-1.938a.521.521 0 00-.383.158.521.521 0 00-.158.383 9.312 9.312 0 002.743 6.607 9.312 9.312 0 006.606 2.742z" fill="#418FDE"/><path stroke="#418FDE" stroke-width=".791" d="M896.88 18.16l1.577-1.55h3.153V13h-4.73z"/></g></svg>
              <p>Private<br />Appointment</p>
            </a>
            <a
              class="buttons__item"
              href="https://chat.satis.fi/?pageID=21752"
              target="_blank"
              v-if="chat"
            >
              <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-3.6%" y="-43.8%" width="107.3%" height="187.5%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><g filter="url(#a)" transform="translate(-1016 7)" fill-rule="nonzero" fill="none"><path d="M1040.83 25.891l1.514-1.286a.82.82 0 01.372-.086h2.573c.362 0 .686-.081.971-.243.286-.162.458-.386.515-.672l-.743-.514a.902.902 0 00-.486-.143h-3.345c-.495 0-.924-.162-1.286-.486a1.547 1.547 0 01-.543-1.2v-2.888h-.057c-.362 0-.672.124-.93.372a1.22 1.22 0 00-.385.915v3.687c0 .343.133.624.4.843.267.22.59.329.972.329h.457v1.372z" fill="#418FDE"/><path d="M1042.887 14.428c-.355 0-.643.106-.85.341-.22.25-.322.567-.322.946v4.802c0 .355.132.656.395.904.272.258.601.385.984.383l3.523.12 1.742 1.393.41-1.54c.52.086.954-.03 1.299-.356.262-.248.395-.549.395-.904v-4.802c0-.355-.133-.656-.395-.904-.339-.32-.824-.383-7.18-.383z" stroke="#418FDE" stroke-width=".856"/></g></svg>
              <p>Live<br />Chat</p>
            </a>
          </div>
          <b-modal id="modal-5" v-model="navModalShow">
            <div class="modal__close" @click="navModalShow = !navModalShow">
              <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
            </div>
            <iframe
              src="https://usopen.formstack.com/forms/hospitality_luxury_suites"
              title="Hospitality Luxury Suites"
            ></iframe>
          </b-modal>
          <b-modal id="modal-5" v-model="privateAppointmentShow" hide-footer>
            <div
              class="modal__close"
              @click="privateAppointmentShow = !privateAppointmentShow"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
            </div>
            <iframe
              class="privateFrame"
              src="https://app.acuityscheduling.com/schedule.php?owner=19209178&amp;calendarID=3713234&field:8429482=Website_Private_Appointment"
              title="Schedule Appointment"
            ></iframe>
          </b-modal>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "TopNavMobile",
  data() {
    return {
      openMenu: false,
      navModalShow: false,
      privateAppointmentShow: false,
      covidShow: true,
      primaryMenu: null,
      contact: null,
      inquiry: null,
      appointment: null,
      chat: null,
      appointmentLink: null,
      travelLink: null,
      schedule: null,
    };
  },
  watch: {
    openMenu: function (val) {
      if (val) {
        document.querySelector("html").style.overflow = "hidden";
      } else {
        document.querySelector("html").style.overflow = "";
      }
    },
  },
  methods: {
    trackingTag: function () {
      var axel = Math.random() + "";
      var a = axel * 10000000000000;
      var axel2 = Math.random() + "";
      var b = axel2 * 10000000000000;

      let floodlightTag = document.createElement("script");
      let floodlightTag2 = document.createElement("script");
      floodlightTag.setAttribute(
        "src",
        "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur002;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
          a +
          "?"
      );
      floodlightTag2.setAttribute(
        "src",
        "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur003;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=" +
          b +
          "?"
      );
      document.body.appendChild(floodlightTag);
      document.body.appendChild(floodlightTag2);
    },
    showModal() {
      this.$refs["covid"].show();
    },
    hideModal() {
      this.$refs["covid"].hide();
    },
    openLightbox() {
      window.fsLightbox2933987.show();
    },
    buyFloodlight(id) {
      window.gtagClick(id);
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.showModal()
    // }, 2000)

    this.primaryMenu = this.$store.state.epitch.primaryMenu;

    this.$store.watch((state) => {
      let sets = state.epitch.ePitch.pages;
      let travel = state.epitch.primaryMenu;
      let sched = this.$store.state.epitch.ePitch.pages;
      if (typeof sets !== "undefined") {
        sets.forEach((page) => {
          if (page.id === "1001") {
            let set = JSON.parse(page.copy);

            this.contact = set["globalLinkOutput"]["cta1"];
            this.inquiry = set["globalLinkOutput"]["cta2"];
            this.appointment = set["globalLinkOutput"]["cta3"];
            this.chat = set["globalLinkOutput"]["cta4"];
            this.appointmentLink = set["calendarLink"];
          }
        });
      }
      if (typeof travel !== "undefined") {
        travel.forEach((link) => {
          if (link.id === "7") {
            if (link.copy) {
              this.travelLink = JSON.parse(link.copy);
            }
          }
        });
      }
      if (typeof sched !== "undefined") {
        sched.forEach((file) => {
          if (file.id === "9") {
            if (file.attachment) {
              let schedule = file.attachment;
              this.schedule = `https://usopen.vipfanportal.com/upload/${schedule}`;
            }
          }
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.topNav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.12);
  z-index: 99;
  &__menu {
    font-family: "interstate", sans-serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-right: 20px;
    margin-right: 5%;
    @media (max-width: $sm) {
      // margin-right: 35px;
      margin-right: 10%;
    }
    img {
      height: 20px;
    }
    button {
      background: #418fde;
      border-radius: 3px;
      border: none;
      height: 31px;
      width: 167px;
      font-size: 11px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      transition: background-color 0.3s ease;
      outline: none;
      &:hover {
        background-color: #3c7fc3;
      }
    }
  }
  .expand-enter-active,
  .expand-leave-active {
    transition: all 0.5s ease-in-out;
    height: calc(100vh - 55px);
    overflow: hidden;
  }
  .expand-enter,
  .expand-leave-to {
    height: 0;
    opacity: 0;
  }

  .collapse-1 {
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.15);
    #topNavMobile {
      position: relative;
      height: calc(75vh - 41px);
      display: flex;
      justify-content: center;
      align-items: center;
      .covid-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background: #418fde;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        h4 {
          font-family: "Interstate-Regular";
          font-size: 10px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
          margin-right: 9px;
        }
        svg {
          width: 14.1px;
          outline: none;
        }
      }
      .topNavMobile__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 45%;
        list-style: none;

        li {
          padding: 0;
          cursor: pointer;
          a {
            text-decoration: none;
            p {
              font-family: "interstate", sans-serif;
              font-weight: 500;
              font-style: normal;
              font-size: 13px;
              color: #7f7f7f;
              letter-spacing: 0;
              text-align: center;
              text-decoration: none;
              transition: color 0.3s ease;
              text-transform: uppercase;
              &:hover {
                color: #418fde;
              }
            }
          }
          // span {
          //   font-style: italic;
          // }
        }
        .active {
          p {
            color: #418fde;
          }
        }
        .nav__link {
          text-decoration: none;
          padding: 0;
          cursor: pointer;
          p {
            font-family: "interstate", sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 13px;
            color: #7f7f7f;
            letter-spacing: 0;
            text-align: center;
            text-decoration: none;
            transition: color 0.3s ease;
            &:hover {
              color: #418fde;
            }
          }
        }
      }
    }
    .topNavMobile__bottom {
      // height: 25%;
      height: calc(25vh - 14px);
      border-top: 1px solid #e1e1e1;
      .social {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 20px 0;
        height: 30%;
        a {
          margin-right: 25px;
          &:last-child {
            margin-right: 0;
          }
        }
        svg {
          // width: 20px;
          height: 15px;
        }
      }
      .buttons {
        display: flex;
        flex-direction: row;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        height: 70%;
        &__item {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #e1e1e1;
          text-decoration: none;
          cursor: pointer;
          &:last-child {
            border-right: none;
          }
          svg {
            height: 80px;
            width: 90px;
            margin-top: -45px;
            outline: none;
          }
          p {
            font-family: "interstate", sans-serif;
            font-weight: 500;
            font-style: normal;
            color: #418fde;
            letter-spacing: 0;
            text-align: center;
            font-size: 11px;
            margin: -20px;
          }
        }
      }
    }
  }
}
.mobileMenuBtn {
  height: 16px;
  width: 24px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  position: absolute;
  top: 20px;
  right: 4%;
  cursor: pointer;
  // z-index: 4 !important;
  z-index: 4;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #373737;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 7px;
    }
    &:nth-child(3) {
      top: 14px;
    }
  }
  &.active {
    span {
      // background: #2b4049;
      background: #373737;
      &:nth-child(1) {
        top: 7px;
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        top: 7px;
        transform: rotate(-135deg);
      }
    }
  }
}
</style>

<style lang="scss">
.topNav {
  nav li.router-link-active {
    color: #418fde;
  }
}
// .fixedPosition {
//   // overflow-y: scroll;
//   // overflow-x: hidden;
//   overflow: hidden;
//   height: 100vh;
//   // position: fixed;
// }
</style>