<template>
  <div id="topNav">
    <SecondaryNav />
    <PrimaryNav />
    <!-- <TertiaryNav /> -->
  </div>
</template>

<script>
import PrimaryNav from "@/components/Layout/TopNav/PrimaryNav";
import SecondaryNav from "@/components/Layout/TopNav/SecondaryNav";
// import TertiaryNav from "@/components/Layout/TopNav/TertiaryNav";

export default {
  name: "TopNav",
  components: {
    PrimaryNav,
    SecondaryNav,
    // TertiaryNav,
  },
};
</script>

<style lang="scss" scoped>
#topNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.12);
}
</style>