<template>
  <div class="footer">
    <SecondaryFooter />
    <PrimaryFooter />
  </div>
</template>

<script>
import PrimaryFooter from "@/components/Layout/Footer/PrimaryFooter"
import SecondaryFooter from "@/components/Layout/Footer/SecondaryFooter"
export default {
  name: "Footer",
  components: {
    PrimaryFooter,
    SecondaryFooter
  }
}
</script>

<style lang="scss" scoped>

</style>