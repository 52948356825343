<template>
  <div>
    <TopNav v-if="!isMobile({ windowWidth })" key="1" class="nav" />
    <TopNavMobile v-else class="mobileNav" key="2" />

    <div class="sections" key="3">
      <router-view />
    </div>
    <Footer key="4" />
  </div>
</template>

<script>
import Section from "@/components/global/DynamicComponent";
import TopNav from "@/components/Layout/TopNav/TopNav";
import TopNavMobile from "@/components/Layout/TopNav/TopNavMobile";
import Footer from "@/components/Layout/Footer/Footer";
import { mapState } from "vuex";
import { TweenMax, TimelineMax } from "gsap/dist/gsap";
import axios from "axios";

export default {
  name: "Layout",
  components: {
    Section,
    TopNav,
    TopNavMobile,
    Footer,
  },
  computed: {
    ...mapState(["global"]),
  },
  data() {
    return {
      loader: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    isMobile(width) {
      return width.windowWidth < 992;
    },
  },
  mounted() {
    setTimeout(() => {
      this.loader = true;
    }, 500);
    let that = this;
    window.addEventListener(
      "resize",
      function () {
        that.windowWidth = window.innerWidth;
      },
      false
    );

    this.$store.watch(
      (state) => {
        return this.$store.state.epitch.ePitch;
      },
      (newValue, oldValue) => {
        // window.console.log(newValue);
        // window.console.log(oldValue);

        if (newValue) {
          const purl = this.$store.state.epitch.ePitch.purl;
          const epitchUrl = this.$store.state.epitch.url;
          const mode = this.$store.state.epitch.ePitch.mode;

          // window.console.log('purl',purl);
          // window.console.log('epitchUrl',epitchUrl);
          // window.console.log('mode',mode);
          if (mode === "live") {
            // Track initial visit - Creates presentationId and sessionId
            axios
              .post(`${epitchUrl}/epitchApi/EpitchLiteTracking.php`, {
                method: "visit",
                purl: purl,
              })
              .then(function (response) {
                if (response.data.status == "ok") {
                  //the following two variables will be declared globally so I can use them again later in subsequent requests
                  window.presentationId = response.data.presentationId;
                  window.sessionDbId = response.data.sessionDbId;
                }
              });
          }
        }
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.nav {
  z-index: 99;
  width: 100%;
}
.mobileNav {
  z-index: 99;
  width: 100%;
}

.sections {
  margin-top: 82px;
  // margin-top: 106px;
  // margin-top: 136px;
  z-index: 1;
  @media (max-width: $lg) {
    margin-top: 30px;
  }
}
</style>

<style lang="scss">
.fsLightboxHandle {
  display: none;
}
#field88850144_1,
#field88850144_2 {
  &::before {
    margin-bottom: 5px;
  }
}
</style>
