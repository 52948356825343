<template>
  <div id="primaryFooter">
    <b-container>
      <div class="footer__container">
        <div @click="scrollToTop" class="footer__float">
          <img src="@/assets/icons/footer/icon_arrow_back-to-top.svg" />
        </div>
        <div class="footer__left">
          <img src="@/assets/icons/footer/logo_white.svg" alt="US-Open" />
          <p>&copy; US Open 2023. All rights reserved.</p>
        </div>
        <div class="footer__right">
          <div class="footer__right-social">
            <a href="https://www.facebook.com/usopentennis/" target="_blank">
              <img
                src="@/assets/icons/footer/icon_social_facebook_white.svg"
                alt="facebook"
              />
            </a>
            <a href="https://www.instagram.com/usopen/?hl=en" target="_blank">
              <img
                src="@/assets/icons/footer/icon_social_instagram_white.svg"
                alt="instagram"
              />
            </a>
            <a
              href="https://twitter.com/usopen?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
            >
              <img
                src="@/assets/icons/footer/icon_social_twitter_white.svg"
                alt="twitter"
              />
            </a>
          </div>
          <div class="footer__right-top">
            <router-link
              active-class="active"
              :to="home.vuePath"
              exact
              v-if="home"
            >
              Home
            </router-link>
            <div class="separator" v-if="home && overlook"></div>
            <router-link
              active-class="active"
              :to="overlook.vuePath"
              v-if="overlook"
            >
              The Overlook at the US Open
            </router-link>
            <div class="separator" v-if="home || overlook"></div>
            <router-link
              active-class="active"
              :to="sixtyEight.vuePath"
              v-if="sixtyEight"
            >
              1968 Room
            </router-link>
            <div class="separator" v-if="sixtyEight && suites"></div>
            <router-link
              active-class="active"
              :to="suites.vuePath"
              v-if="suites"
            >
              Luxury Suites
            </router-link>
            <div class="separator" v-if="suites && centerCourtClub"></div>
            <router-link
              active-class="active"
              :to="centerCourtClub.vuePath"
              v-if="centerCourtClub"
            >
              American Express&reg; Center Court Club
            </router-link>
            <div class="separator" v-if="centerCourtClub"></div>
          </div>
          <div class="footer__right-bottom">
            <router-link
              active-class="active"
              :to="privateHospitality.vuePath"
              v-if="privateHospitality"
            >
              Private Hospitality
            </router-link>
            <div
              class="separator"
              v-if="centerCourtClub || privateHospitality"
            ></div>
            <!-- <a :href="tours.redirectLink" target="_blank" v-if="tours"> -->
            <a
              href="https://onlocationexp.com/tennis/us-open-tennis-tickets?utm_source=hospitality.usopen.org&utm_medium=referral&utm_campaign=us_open-travel_packages_main_menu"
              target="_blank"
              v-if="tours"
            >
              Steve Furgal's International Tennis Tours
            </a>
            <div class="separator"></div>
            <a
              href="https://www.usopen.org/en_US/info/privacy/index.html?promo=footer"
              target="_blank"
              >Privacy Policy</a
            >
            <div class="separator"></div>
            <a
              href="https://www.usopen.org/en_US/info/terms/index.html?promo=footer"
              target="_blank"
              >Terms of Use</a
            >
            <!-- <div class="separator"></div>
            <router-link active-class="active" to="/scheduleofplay">Schedule of Play</router-link> -->
          </div>
          <div class="footer__right-mobile">
            <a
              href="https://www.usopen.org/en_US/info/privacy/index.html?promo=footer"
              target="_blank"
              >Privacy Policy</a
            >
            <div class="separator"></div>
            <a
              href="https://www.usopen.org/en_US/info/terms/index.html?promo=footer"
              target="_blank"
              >Terms of Use</a
            >
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PrimaryFooter",
  data() {
    return {
      primaryMenu: null,
      home: null,
      overlook: null,
      sixtyEight: null,
      suites: null,
      centerCourtClub: null,
      privateHospitality: null,
      tours: null,
      travelLink: null,
      courtsidePremier: null,
    };
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.$store.watch((state) => {
      let sets = state.epitch.primaryMenu;
      sets.forEach((page) => {
        if (page.id === "1") {
          this.home = page;
        } else if (page.id === "2") {
          this.overlook = page;
        } else if (page.id === "3") {
          this.sixtyEight = page;
        } else if (page.id === "4") {
          this.suites = page;
        } else if (page.id === "5") {
          this.centerCourtClub = page;
        } else if (page.id === "6") {
          this.privateHospitality = page;
        } else if (page.id === "7") {
          this.tours = JSON.parse(page.copy);
        } else if (page.id === "12") {
          this.courtsidePremier = page;
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
#primaryFooter {
  // height: 175px;
  background: linear-gradient(-65deg, #262626 50%, #333333 50%);
  .footer {
    &__float {
      position: absolute;
      top: -18%;
      right: 50%;
      transform: translate(50%);
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.18);
      border-radius: 28.5px;

      cursor: pointer;
      @media (max-width: $lg) {
        right: 50%;
        top: -13%;
      }
    }
    &__container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 175px;
      font-family: "interstate", sans-serif;
      font-size: 10px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: 0;
      color: #ffffff;
      .active {
        font-weight: 600;
      }
      @media (max-width: $lg) {
        flex-direction: column;
        height: auto;
        padding: 75px 0 60px 0;
      }
    }
    &__left {
      @media (max-width: $lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      img {
        height: 20px;
      }
      p {
        line-height: 22px;
        padding-top: 20px;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      width: 60%;
      @media (max-width: $lg) {
        align-items: center;
        width: 100%;
        margin-top: 25px;
      }
      &-social {
        display: flex;
        justify-content: space-between;
        width: 17%;
        img {
          height: 15px;
          fill: #ffffff;
          @media (max-width: $lg) {
            height: 17px;
          }
        }
      }
      &-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
        @media (min-width: $lg) {
          display: none;
        }
      }
      &-top,
      &-bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media (max-width: $lg) {
          display: none;
        }
      }
      &-top {
        margin-top: 25px;
      }
      &-bottom {
        margin-top: 5px;
        margin-right: -5px;
      }
      a {
        text-decoration: none;
        color: #ffffff;
        line-height: 18px;
        padding: 0 5px;
      }
      .separator {
        background: #ffffff;
        width: 3px;
        height: 3px;
        border-radius: 100%;
      }
    }
  }
}
</style>