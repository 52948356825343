import Vue from "vue";
import Router from "vue-router";
import Confirmation from "@/components/sections/Confirmation/Confirmation";

Vue.use(Router);

const url = location.pathname.split("/");
let purl = url[1];
// let purl = url[2];

if (location.search.includes("?preview.")) {
  window.console.log("preview");
  let previewPath = location.search;
  purl = previewPath.replace("?preview", "preview");
  window.location.href = `/${purl}`;
  // window.location.href = `/preview/${purl}`;
}

if (typeof purl === "undefined" || purl.split(".").length <= 1) {
  purl = "";
}

export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return document
        .querySelector(to.hash)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: `${purl}`,
  // base: `preview/${purl}`,
  routes: [
    {
      path: "/confirmation",
      name: "Confirmation",
      component: Confirmation,
    },
    { path: "/upload" },
  ],
});
