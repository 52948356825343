<template>
  <div
    id="primaryNav"
    data-aos="fade-down"
    data-aos-duration="300"
    data-aos-delay="100"
  >
    <b-container>
      <nav id="mainNav" v-if="primaryMenu">
        <b-nav class="mainNav__container">
          <a
            href="https://www.usopen.org"
            class="logo"
            target="_blank"
            data-aos="fade-down"
            data-aos-delay="500"
          >
            <img src="@/assets/icons/nav/logo_main.svg" />
          </a>
          <li
            v-for="(page, index) in primaryMenu"
            :key="'nav' + index"
            data-aos="fade-down"
            :data-aos-delay="600 + index * 50"
          >
            <router-link
              v-if="page.title == 'Home'"
              active-class="active"
              exact
              :to="page.vuePath"
            >
              <p>{{ page.title }}</p>
            </router-link>
            <router-link
              v-else-if="page.title !== ('Travel Packages' || 'Schedule')"
              active-class="active"
              :to="page.vuePath"
            >
              <p
                class="overlook"
                v-if="page.title == 'The Overlook at the US Open'"
              >
                THE OVERLOOK <br />
                AT THE US OPEN
              </p>
              <p v-else-if="page.title == '1968 Room'">
                1968 <br />
                ROOM
              </p>
              <p v-else-if="page.title == 'Luxury Suites'">
                Luxury <br />
                Suites
              </p>
              <p
                class="centre-court"
                v-else-if="page.title == 'American Express Center Court Club'"
              >
                AMERICAN EXPRESS&reg; <br />
                CENTER COURT CLUB
              </p>
              <p v-else-if="page.title == 'Private Hospitality'">
                Private Hospitality <br />
                Space
              </p>
              <p v-else-if="page.title == 'Courtside Premier'">
                Courtside <br />
                Premier
              </p>
              <p v-else-if="page.title == 'Player Cafe Courtside Club'">
                Player Cafe <br />
                Courtside Club
              </p>
              <p v-else>{{ page.title }}</p>
            </router-link>
            <a
              v-if="page.title == 'Schedule' && page.attachment"
              v-bind:href="`https://usopen.vipfanportal.com/upload/${page.attachment}`"
              target="_blank"
            >
              <p>{{ page.title }}</p>
            </a>
            <!-- <a
              v-else-if="page.title == 'Travel Packages'"
              :href="travelLink"
              target="_blank"
            > -->
            <a
              v-else-if="page.title == 'Travel Packages'"
              href="https://onlocationexp.com/tennis/us-open-tennis-tickets?utm_source=hospitality.usopen.org&utm_medium=referral&utm_campaign=us_open-travel_packages_main_menu"
              target="_blank"
            >
              <p>
                TRAVEL <br />
                PACKAGES
              </p>
            </a>
          </li>
          <!-- <li v-if="schedule" data-aos="fade-down" :data-aos-delay="600 + (primaryMenu.length * 50)">
            <a v-bind:href="schedule" target="_blank">
              <p>Schedule</p>
            </a>
          </li> -->
        </b-nav>
      </nav>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PrimaryNav",
  data() {
    return {
      primaryMenu: null,
      travelLink: null,
      schedule: null,
    };
  },
  mounted() {
    this.primaryMenu = this.$store.state.epitch.primaryMenu;
    // window.console.log("menu", this.$store.state.epitch);

    this.$store.watch((state) => {
      let pages = this.$store.state.epitch.primaryMenu;
      let sched = this.$store.state.epitch.ePitch.pages;
      if (typeof pages !== "undefined") {
        pages.forEach((page) => {
          if (page.id === "7") {
            let link = JSON.parse(page.copy);
            this.travelLink = link["redirectLink"];
          }
        });
      }
      if (typeof sched !== "undefined") {
        sched.forEach((file) => {
          if (file.id === "9") {
            if (file.attachment) {
              let schedule = file.attachment;
              this.schedule = `https://usopen.vipfanportal.com/upload/${schedule}`;
            }
          }
        });
      }
    });

    // this.$store.watch(
    //   newValue => {
    //     if(newValue.userInfo.userInfo.length > 0){
    //       this.repName = newValue.userInfo.userInfo[0].repName
    //       this.repEmail = newValue.userInfo.userInfo[0].repEmail
    //       this.repPhone = newValue.userInfo.userInfo[0].repPhone
    //     }
    //   }
    // }
  },
  watch: {
    $route(to, from) {
      document.querySelectorAll("#mainNav .aos-init").forEach((el) => {
        el.classList.remove("aos-animate");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#primaryNav {
  padding: 17px 0;
  width: 100%;
  font-family: "interstate", sans-serif;
  font-weight: 500;
  font-style: normal;
  background: #ffffff;
  z-index: 99;
  .container {
    @media (min-width: 992px) {
      max-width: 980px;
    }
    @media (min-width: 1200px) {
      max-width: 1180px;
    }
    @media (min-width: 1400px) {
      max-width: 1300px;
    }
  }
  #mainNav {
    .mainNav__container {
      display: flex;
      // justify-content: space-between;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      list-style: none;
      max-height: 32px;
      a {
        text-decoration: none;
        // &.logo {
        // margin-right: 30px;
        // }
        p {
          font-size: 11px;
          color: $text-grey;
          letter-spacing: 0;
          text-align: center;
          text-decoration: none;
          max-width: 120px;
          transition: color 0.3s ease;
          cursor: pointer;
          &:hover {
            color: #418fde;
          }
          // @media (max-width: 1200px) {
          //   font-size: 10px;
          // }
        }
      }
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        margin: 0 2.1%;
        @media (max-width: 1200px) {
          margin: 0 1.6%;
        }
        p {
          font-size: 11px;
          color: $text-grey;
          letter-spacing: 0;
          text-align: center;
          text-decoration: none;
          max-width: 120px;
          transition: color 0.3s ease;
          text-transform: uppercase;
          white-space: nowrap;
          cursor: pointer;
          @media (max-width: 1200px) {
            font-size: 10px;
          }
          &.overlook {
            min-width: 87.98px;
          }
          &.centre-court {
            min-width: 118.3px;
          }
          &:hover {
            color: #418fde;
          }
        }
      }
      .active {
        p {
          color: #418fde;
        }
      }
    }
  }
}
</style>